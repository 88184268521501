import React from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Login from "./pages/Login"
import Dashboard from "./pages/Dashboard"

const router = createBrowserRouter([
    { path: "/login", element: <Login /> },
    { path: "/", element: <Dashboard /> },
])

export default function App() {
    return (
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    )
}
