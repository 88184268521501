import React from "react"

const Button = ({ children, className, ...props }) => (
    <button
        className={`border-2 border-gray-400 hover:border-gray-600 rounded-md font-semibold py-2 px-4 leading-tight text-gray-700 focus:outline-none focus:shadow-outline-blue bg-transparent ${className}`}
        {...props}>
        {children}
    </button>
)

export default Button
