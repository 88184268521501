import React, { useState, useEffect } from "react"
import { axiosInstance } from "../axiosConfig"
import Button from "./Button"
import Container from "./Container"
import Table from "./InvoiceTable"

const InvoicesModule = ({ invoices, setInvoices, clients, setClients, className, props }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newInvoice, setNewInvoice] = useState({
    client_id: "",
    invoice_amount: 0,
    payment_type: "Transakcijski račun",
    note: "Odvjetnička usluga ",
  })
  const [currentlyEditingId, setCurrentlyEditingId] = useState(null)
  const [searchTerm, setSearchTerm] = useState("")

  const fetchData = async () => {
    const { data: invoicesData } = await axiosInstance.get("/invoices")
    const { data: clientsData } = await axiosInstance.get("/clients")

    const invoicesDto = invoicesData.map((invoice) => {
      const client = clientsData.find((client) => client.id === invoice.client_id)
      if (!client) return { ...invoice, client: { name: "Ne postoji" } }
      return { ...invoice, client: client }
    })

    setClients(clientsData)
    setInvoices(invoicesDto)
  }

  const filteredClients = clients.filter((client) => {
    return !client.removed && client.name.toLowerCase().includes(searchTerm.toLowerCase())
  })

  const handleDeleteInvoice = async (invoiceId) => {
    await axiosInstance.delete(`/invoices/${invoiceId}`)
    fetchData()
  }

  const handleAddInvoice = async (e) => {
    e.preventDefault()
    const invoice = newInvoice
    if (!newInvoice.client_id) {
      if (filteredClients.length === 0) {
        alert("Nije odabrana stranka")
        return
      }
      invoice.client_id = filteredClients[0].id
    }
    await axiosInstance.post("/invoices", newInvoice)
    setIsModalOpen(false)
    setCurrentlyEditingId(null)
    fetchData()
    setNewInvoice({
      client_id: "",
      invoice_amount: 0,
      payment_type: "Transakcijski račun",
      note: "Odvjetnička usluga",
    })
  }

  const handleOpenEditWindow = (invoiceId) => {
    setCurrentlyEditingId(invoiceId)
    setIsModalOpen(true)
    const invoice = invoices.find((invoice) => invoice.id === invoiceId)
    setNewInvoice({
      client_id: invoice.client_id,
      invoice_amount: invoice.invoice_amount,
      payment_type: invoice.payment_type,
      note: invoice.note,
    })
  }

  const handleSubmit = (e) => {
    if (currentlyEditingId) {
      handleEditInvoice(e)
    } else {
      handleAddInvoice(e)
    }
  }

  const handleCancel = () => {
    if (currentlyEditingId) {
      setCurrentlyEditingId(null)
    }
    setIsModalOpen(false)
    setNewInvoice({
      client_id: "",
      invoice_amount: 0,
      payment_type: "Transakcijski račun",
      note: "Odvjetnička usluga",
    })

    setSearchTerm("")
  }

  const handleEditInvoice = async (e) => {
    e.preventDefault()
    const invoice = newInvoice
    if (!newInvoice.client_id) {
      if (filteredClients.length === 0) {
        alert("Nije odabrana stranka")
        return
      }
      invoice.client_id = filteredClients[0].id
    }
    await axiosInstance.put(`/invoices/${currentlyEditingId}`, newInvoice)
    setIsModalOpen(false)
    fetchData()
    setNewInvoice({
      client_id: "",
      invoice_amount: 0,
      payment_type: "Transakcijski račun",
      note: "Odvjetnička usluga",
    })
  }

  return (
    <div className={`${className} flex flex-col items-stretch`} {...props}>
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Računi</h2>
      <Container className="bg-gray-50 shadow-md rounded px-8 pt-6 pb-8 mb-4 h-full flex flex-col justify-between">
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-container">
              <form>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="client_id">
                  Stranka
                </label>
                <div className="flex">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    className="w-1/5 bg-gray-100 rounded py-2 px-3 focus:outline-none focus:shadow-outline"
                    placeholder="Pretraga stranaka..."
                  />
                  <select
                    className="w-1/3 ml-6 shadow border rounded py-2 px-3 text-gray-700 focus:shadow-outline"
                    id="client_id"
                    value={newInvoice.client_id}
                    onChange={(e) => setNewInvoice({ ...newInvoice, client_id: e.target.value })}
                  >
                    {filteredClients.map((client) => (
                      <option key={client.id} value={client.id}>
                        {client.name}
                      </option>
                    ))}
                  </select>
                </div>
                <label className="block text-gray-700 text-sm font-bold mb-2 mt-6" htmlFor="invoice_amount">
                  Iznos
                </label>
                <input
                  className=" w-1/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="invoice_amount"
                  type="number"
                  value={newInvoice.invoice_amount}
                  onChange={(e) => setNewInvoice({ ...newInvoice, invoice_amount: e.target.value })}
                />
                <label className="block text-gray-700 text-sm font-bold mb-2 mt-6" htmlFor="payment_type">
                  Način plaćanja
                </label>
                <select
                  className="w-1/3 shadow border rounded py-2 px-3 text-gray-700 focus:shadow-outline"
                  id="payment_type"
                  type="text"
                  value={newInvoice.payment_type}
                  onChange={(e) => setNewInvoice({ ...newInvoice, payment_type: e.target.value })}
                >
                  <option value="kartica">Transakcijski račun</option>
                  <option value="gotovina">Gotovina</option>
                </select>
                <label className="block text-gray-700 text-sm font-bold mb-2 mt-6" htmlFor="note">
                  Napomena
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="note"
                  type="text"
                  value={newInvoice.note}
                  onChange={(e) => setNewInvoice({ ...newInvoice, note: e.target.value })}
                />
                <div className="flex items-center justify-between mt-4">
                  <Button className="w-1/3" onClick={handleSubmit}>
                    {currentlyEditingId ? "Uredi " : "Kreiraj "} račun
                  </Button>
                  <Button className="w-1/3" onClick={handleCancel}>
                    Odustani
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
        {!isModalOpen && (
          <>
            <Table
              invoices={invoices}
              deleteInvoice={handleDeleteInvoice}
              handleOpenEditWindow={handleOpenEditWindow}
            />
            <div className="flex justify-end">
              <Button className="w-1/3 mt-4" onClick={() => setIsModalOpen(true)}>
                Novi račun
              </Button>
            </div>
          </>
        )}
      </Container>
    </div>
  )
}

export default InvoicesModule
