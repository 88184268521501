import React, { useState, useEffect } from "react"
import { axiosInstance } from "../axiosConfig"
import Button from "./Button"
import Table from "./ClientTable"
import Container from "./Container"

const ClientsModule = ({ clients, setClients, className, props }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [newClient, setNewClient] = useState({ name: "", oib: "", address: "" })

    const handleDeleteClient = async (clientId) => {
        await axiosInstance.delete(`/clients/${clientId}`)
        const updatedClients = clients.filter((client) => client.id !== clientId)
        setClients(updatedClients)
    }

    const handleAddClient = async (e) => {
        e.preventDefault()
        await axiosInstance.post("/clients", newClient)
        setIsModalOpen(false)
        const { data } = await axiosInstance.get("/clients")
        setClients(data)
        setNewClient({ name: "", oib: "", address: "" })
    }

    return (
        <div className={`${className} flex flex-col items-stretch`} {...props}>
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Stranke</h2>
            <Container className="bg-gray-50 shadow-md rounded px-8 pt-6 pb-8 mb-4 h-full flex flex-col justify-between">
                {isModalOpen && (
                    <div className="modal-overlay">
                        <div className="modal-container">
                            <form>
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                    Ime
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="name"
                                    type="text"
                                    value={newClient.name}
                                    onChange={(e) => setNewClient({ ...newClient, name: e.target.value })}
                                    required
                                />
                                <label className="block text-gray-700 text-sm font-bold mb-2 mt-4" htmlFor="oib">
                                    OIB
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="oib"
                                    type="text"
                                    value={newClient.oib}
                                    onChange={(e) => setNewClient({ ...newClient, oib: e.target.value })}
                                />
                                <label className="block text-gray-700 text-sm font-bold mb-2 mt-4" htmlFor="address">
                                    Adresa
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="address"
                                    type="text"
                                    value={newClient.address}
                                    onChange={(e) => setNewClient({ ...newClient, address: e.target.value })}
                                />
                                <div className="flex items-center justify-between mt-4">
                                    <Button className="w-1/3" onClick={handleAddClient}>
                                        Dodaj stranku
                                    </Button>
                                    <Button className="w-1/3" onClick={() => setIsModalOpen(false)}>
                                        Odustani
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
                {!isModalOpen && (
                    <>
                        <Table clients={clients} deleteClient={handleDeleteClient} />
                        <div className="flex justify-end">
                            <Button className="w-1/3" onClick={() => setIsModalOpen(true)}>
                                Nova stranka
                            </Button>
                        </div>
                    </>
                )}
            </Container>
        </div>
    )
}

export default ClientsModule
