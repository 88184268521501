import React, { useState } from "react"
import { axiosInstance } from "../axiosConfig"
import Button from "./Button"

export const InvoiceTable = ({ invoices, deleteInvoice, handleOpenEditWindow }) => {
    const [searchTerm, setSearchTerm] = useState("")
    const [searchPeriod, setSearchPeriod] = useState({
        startDate: getStartDate(),
        endDate: getEndDate(),
    })

    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    const generatePdf = async (id) => {
        const response = await axiosInstance.get(`/pdf/${id}`, {
            responseType: "blob",
        })
        window.open(URL.createObjectURL(response.data))
    }

    const filteredInvoices = invoices
        .filter((invoice) => {
            return (
                invoice.client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                invoice.client.oib.toLowerCase().includes(searchTerm.toLowerCase()) ||
                invoice.invoice_number.toLowerCase().includes(searchTerm.toLowerCase())
            )
        })
        .filter((invoice) => {
            const invoiceDate = Date.parse(invoice.invoice_date)
            const startDate = new Date(searchPeriod.startDate)
            const endDate = new Date(searchPeriod.endDate)

            return invoiceDate >= startDate && invoiceDate <= endDate
        })

    return (
        <div className="max-h-80">
            <div className="flex justify-start items-center mb-4">
                <input
                    className="bg-gray-100 rounded w-1/4 py-2 px-3  focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Pretraga izdanih računa..."
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <input
                    className="bg-gray-100 rounded w-1/6 py-2 px-3 mx-4  focus:outline-none focus:shadow-outline"
                    type="date"
                    value={searchPeriod.startDate}
                    onChange={(e) => setSearchPeriod({ ...searchPeriod, startDate: e.target.value })}
                />
                <input
                    className="bg-gray-100 rounded w-1/6 py-2 px-3 focus:outline-none focus:shadow-outline"
                    type="date"
                    value={searchPeriod.endDate}
                    onChange={(e) => setSearchPeriod({ ...searchPeriod, endDate: e.target.value })}
                />
            </div>
            <div className="overflow-auto mb-4 max-h-64">
                <table className="w-full text-left table-collapse table-fixed">
                    <thead>
                        <tr>
                            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-300 w-2/12">Broj računa</th>
                            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-300 w-3/12">Stranka</th>
                            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-300 w-3/12">Usluga</th>
                            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-300 w-1/12">Datum</th>
                            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-300 w-1/12">Iznos</th>
                            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-300 w-1/12"></th>
                            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-300 w-1/12"></th>
                            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-300 w-1/12"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredInvoices.length ? (
                            filteredInvoices.map((invoice) => (
                                <tr key={invoice.id}>
                                    <td className="p-2 border-t border-gray-200">{invoice.invoice_number}</td>
                                    <td className="p-2 border-t border-gray-200">{invoice.client.name}</td>
                                    <td className="p-2 border-t border-gray-200">{invoice.note}</td>
                                    <td className="p-2 border-t border-gray-200">{formatDate(invoice.invoice_date)}</td>
                                    <td className="p-2 border-t border-gray-200">{invoice.invoice_amount} €</td>
                                    <td className="p-2 border-t border-gray-200 w-1/8">
                                        <div className="flex justify-end">
                                            <Button
                                                className="bg-gray-700 rounded-full px-2 py-1 text-white text-sm focus:outline-none"
                                                onClick={() => generatePdf(invoice.id)}>
                                                PDF
                                            </Button>
                                        </div>
                                    </td>
                                    <td className="p-2 border-t border-gray-200 w-1/8">
                                        <div className="flex justify-end">
                                            <Button
                                                className="bg-gray-700 rounded-full px-2 py-1 text-white text-sm focus:outline-none"
                                                onClick={() => handleOpenEditWindow(invoice.id)}>
                                                Uredi
                                            </Button>
                                        </div>
                                    </td>
                                    <td className="p-2 border-t border-gray-200 w-1/8">
                                        <div className="flex justify-end">
                                            <Button
                                                className="bg-red-500 rounded-full px-2 py-1 text-white text-sm focus:outline-none"
                                                onClick={() => deleteInvoice(invoice.id)}>
                                                Obriši
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className="text-sm font-semibold p-2">Nema rezultata</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="w-1/2">
                <p className="font-semibold">Ukupno naplaćeno: {calculateTotal(filteredInvoices)} €</p>
            </div>
        </div>
    )
}

export default InvoiceTable

const getStartDate = () => {
    const date = new Date(new Date().getFullYear(), 0, 1)
    const dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 10)
    return dateString
}

const getEndDate = () => {
    const date = new Date()
    const dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 10)
    return dateString
}

const formatDate = (date) => {
    const newDate = new Date(date)
    const year = newDate.getFullYear()
    const month = newDate.getMonth() + 1
    const day = newDate.getDate()

    return `${day}.${month}.${year}.`
}

const calculateTotal = (invoices) => {
    let total = 0
    invoices.forEach((invoice) => {
        total += Number.parseFloat(invoice.invoice_amount)
    })
    return total.toFixed(2)
}
