import axios from "axios"

const axiosConfig = {
    baseURL: "/api",
}

const axiosInstance = axios.create(axiosConfig)

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("jwt")
        config.headers["x-access-tokens"] = token
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

export { axiosInstance }
