import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { axiosInstance } from "../axiosConfig"

import ClientsModule from "../components/ClientsModule"
import InvoicesModule from "../components/InvoicesModule"
import SettingsModule from "../components/SettingsModule"

const Dashboard = () => {
    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        account_number: "",
    })
    const [clients, setClients] = useState([])
    const [invoices, setInvoices] = useState([])

    const location = useLocation()
    const navigate = useNavigate()

    const isFirstRender = React.useRef(true)

    useEffect(() => {
        //if (isFirstRender.current) {
        //    isFirstRender.current = false
        //    return
        //}

        const fetchData = async () => {
            try {
                const { data } = await axiosInstance.get("/users")
                setUser(data)
            } catch (error) {
                localStorage.removeItem("jwt")
                navigate("/login")
                return
            }

            const { data: invoicesData } = await axiosInstance.get("/invoices")
            const { data: clientsData } = await axiosInstance.get("/clients")

            const invoicesDto = invoicesData.map((invoice) => {
                const client = clientsData.find((client) => client.id === invoice.client_id)
                if (!client) return { ...invoice, client: { name: "Ne postoji" } }
                return { ...invoice, client: client }
            })

            const activeClients = clientsData.filter((client) => !client.removed)

            setClients(activeClients)
            setInvoices(invoicesDto)
        }
        fetchData()
    }, [location, navigate])

    return (
        <div className="h-full flex justify-center items-center">
            <div className="m-8 w-full max-w-7xl gap-12 grid grid-cols-3 justify-items-center">
                <InvoicesModule
                    invoices={invoices}
                    setInvoices={setInvoices}
                    clients={clients}
                    setClients={setClients}
                    className="col-span-3 w-full"
                />
                <ClientsModule clients={clients} setClients={setClients} className="col-span-2 w-full" />
                <SettingsModule user={user} setUser={setUser} className="w-full" />
            </div>
        </div>
    )
}

export default Dashboard
