import React, { useState } from "react"
import Button from "./Button"

export const ClientsTable = ({ clients, deleteClient }) => {
    const [searchTerm, setSearchTerm] = useState("")

    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    const filteredClients = clients.filter((client) => {
        return (
            (client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                client.oib.toLowerCase().includes(searchTerm.toLowerCase())) &&
            !client.removed
        )
    })

    return (
        <div className="max-h-80">
            <input
                className="bg-gray-100 rounded w-full py-2 px-3 mb-4 focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Pretraga stranaka..."
                value={searchTerm}
                onChange={handleSearch}
            />
            <div className="overflow-auto mb-4 max-h-64">
                <table className="w-full text-left table-collapse table-fixed">
                    <thead>
                        <tr>
                            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-300 w-2/6">Ime</th>
                            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-300 w-1/6">OIB</th>
                            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-300 w-2/6">Adresa</th>
                            <th className="text-sm font-semibold text-gray-700 p-2 bg-gray-300 w-1/6"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredClients.length ? (
                            filteredClients.map((client) => (
                                <tr key={client.id}>
                                    <td className="p-2 border-t border-gray-200">{client.name}</td>
                                    <td className="p-2 border-t border-gray-200">{client.oib}</td>
                                    <td className="p-2 border-t border-gray-200">{client.address}</td>
                                    <td className="p-2 border-t border-gray-200 flex justify-end">
                                        <Button
                                            className="bg-red-500 rounded-full px-2 py-1 text-white text-sm focus:outline-none"
                                            onClick={() => deleteClient(client.id)}>
                                            Obriši
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className="text-sm font-semibold p-2">Nema rezultata</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ClientsTable
