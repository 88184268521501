import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { axiosInstance } from "../axiosConfig"
import Button from "../components/Button"

const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")

    const navigate = useNavigate()

    useEffect(() => {
        // If the user is already logged in, redirect them to the dashboard
        if (localStorage.getItem("jwt")) {
            navigate("/")
        }
    }, [navigate])

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            const response = await axiosInstance.post("/auth", { email, password })
            const { token } = response.data

            if (!token) {
                setError("Invalid credentials")
                return
            }

            localStorage.setItem("jwt", token)
            console.log("Successfully logged in")

            navigate("/")
        } catch (err) {
            setError(err.response.data.error)
        }
    }

    return (
        <div className="h-screen flex justify-center items-center">
            <form onSubmit={handleSubmit} className="m-8 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        Email adresa
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                        Lozinka
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <div className="text-red-500 text-xs italic">{error}</div>}
                <div className="flex items-center justify-between">
                    <Button type="submit">Prijava</Button>
                </div>
            </form>
        </div>
    )
}

export default Login
