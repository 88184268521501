import React, { useEffect, useState } from "react"
import { axiosInstance } from "../axiosConfig"
import Button from "./Button"
import Container from "./Container"

const SettingsModule = ({ user, setUser, className, props }) => {
    const [isEditing, setIsEditing] = useState(false)

    const handleEdit = () => {
        setIsEditing(true)
    }

    const handleSave = async () => {
        setIsEditing(false)
        const { data } = await axiosInstance.put("/users", user)
        setUser(data)
    }

    // RAČUNI PRETRAGA PO VREMENU

    return (
        <div className={`${className} flex flex-col items-stretch`} {...props}>
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Postavke</h2>
            <Container className="bg-gray-50 shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="first_name">
                        Ime
                    </label>
                    {isEditing ? (
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="name"
                            type="text"
                            value={user.name}
                            onChange={(e) => setUser({ ...user, name: e.target.value })}
                        />
                    ) : (
                        <div className="text-gray-700">{user.name}</div>
                    )}
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        Email adresa
                    </label>
                    {isEditing ? (
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email"
                            type="text"
                            value={user.email}
                            onChange={(e) => setUser({ ...user, email: e.target.value })}
                        />
                    ) : (
                        <div className="text-gray-700">{user.email}</div>
                    )}
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="oib">
                        OIB
                    </label>
                    {isEditing ? (
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="oib"
                            type="text"
                            value={user.oib}
                            onChange={(e) => setUser({ ...user, oib: e.target.value })}
                        />
                    ) : (
                        <div className="text-gray-700">{user.oib}</div>
                    )}
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                        Telefon
                    </label>
                    {isEditing ? (
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="phone"
                            type="text"
                            value={user.phone}
                            onChange={(e) => setUser({ ...user, phone: e.target.value })}
                        />
                    ) : (
                        <div className="text-gray-700">{user.phone}</div>
                    )}
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
                        Adresa
                    </label>
                    {isEditing ? (
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="address"
                            type="text"
                            value={user.address}
                            onChange={(e) => setUser({ ...user, address: e.target.value })}
                        />
                    ) : (
                        <div className="text-gray-700">{user.address}</div>
                    )}
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="account_number">
                        IBAN
                    </label>
                    {isEditing ? (
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="account_number"
                            type="text"
                            value={user.account_number}
                            onChange={(e) => setUser({ ...user, account_number: e.target.value })}
                        />
                    ) : (
                        <div className="text-gray-700">{user.account_number}</div>
                    )}
                </div>
                <div className="flex justify-end">
                    {isEditing ? (
                        <Button className="w-1/3" onClick={handleSave}>
                            Spremi
                        </Button>
                    ) : (
                        <Button className="w-1/3" onClick={handleEdit}>
                            Uredi
                        </Button>
                    )}
                </div>
            </Container>
        </div>
    )
}

export default SettingsModule
